<template>
  <div class="cash">
    <div class="box">
      <div class="p16">
        <div class="titles">
          <div>
            <div>
              <el-select v-model="status" @change="select_change" placeholder="请选择卡片状态">
                <el-option v-for="item in select1" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
              <el-select v-model="use_status" @change="select_change" placeholder="请选择使用状态">
                <el-option v-for="item in select2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
              <el-select v-model="order_by_desc" :disabled="order_by_desc_dis" @change="select_change" placeholder="请选择倒序参数">
                <el-option v-for="item in select3" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
              <el-select v-model="order_by_esc" :disabled="order_by_dis" @change="select_change" placeholder="请选择正序参数">
                <el-option v-for="item in select4" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="number-des">
              <el-input v-model="card_number_start" :clearable="true" @clear="toSearch()" placeholder="请输入起始卡号"></el-input>
              <el-input v-model="card_number_end" :clearable="true" @clear="toSearch()" placeholder="请输入结束卡号"></el-input>
              <el-input v-model="id" :clearable="true" @clear="toSearch()" placeholder="请输入用户ID号"></el-input>
              <el-button class="" type="primary" @click="toSearch()">搜索</el-button>
            </div>
          </div>
          <div>
            <el-button class="" size="small" type="primary" @click="toVeri('激活', 1)">激活</el-button>
            <!-- 测试 激活使用 -->
            <!-- <el-button class="qxbtn" type="danger" @click="editBtn('激活')">激活</el-button> -->
            <el-button class="" size="small" type="primary" @click="toVeri('延期', 2)">延期</el-button>
            <el-button class="" size="small" type="primary" @click="toVeri('充值', 3)">充值</el-button>
            <el-button class="" size="small" type="info" @click="toVeri('明细', 4)">明细</el-button>
            <!-- 测试 明细使用 -->
            <!-- <el-button class="qxbtn" type="danger" @click="vipInfo('明细')">明细</el-button> -->
            <el-button class="" size="small" type="warning" @click="toVeri('冻结', 5)">冻结</el-button>
             <!-- 冻结 激活使用 -->
            <!-- <el-button class="qxbtn" type="danger" @click="editBtn('冻结')">冻结</el-button> -->
            <el-button class="" size="small" type="danger" @click="toVeri('删除', 6)">删除</el-button>
            <!-- 删除 激活使用 -->
            <!-- <el-button class="qxbtn" type="danger" @click="delBtn('删除')">删除</el-button> -->
            <el-button type="primary" class="ssbtn" style="width: 6.75rem" @click="handleBack">上一页</el-button>
          </div>
        </div>
        <div class="tableBox">
          <el-table
            :data="tableData"
            v-loading="loading"
            border
            style="width: 100%"
            stripe
            :header-cell-style="{
              background: 'linear-gradient(0deg, #EDEDED 0%, #F7F7F7 51%, #EDEDED 100%)',
              'text-align': 'center',
            }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-width="80px" inline class="demo-table-expand">
                  <div class="expandForm">
                    <el-form-item label="制卡备注">
                      <span>{{ props.row.remarks }}</span>
                    </el-form-item>
                    <el-form-item label="激活备注">
                      <span>{{ props.row.activation_remarks }}</span>
                    </el-form-item>
                    <el-form-item label="冻结原因">
                      <span>{{ props.row.frozen_reason }}</span>
                    </el-form-item>
                    <el-form-item label="使用说明">
                      <span>{{ props.row.des }}</span>
                    </el-form-item>
                  </div>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column prop="cname" label="批次"  width="140">
              <template slot-scope="scope">
                <div style="cursor: pointer">{{ scope.row.batch }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="out90" label="卡号">
              <template slot-scope="scope">
                <div>{{ scope.row.card_number }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="in20" label="有效期"  width="140">
              <template slot-scope="scope">
                <div>{{ scope.row.end_time }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="in20" label="类型">
              <template slot-scope="scope">
                <div v-if="scope.row.type == 1">满减券</div>
                <div v-else-if="scope.row.type == 2">兑换券</div>
                <div v-else-if="scope.row.type == 3">贵宾券</div>
                <div v-else>代金券</div>
              </template>
            </el-table-column>
            <el-table-column prop="in20" label="面额">
              <template slot-scope="scope">
                <div>{{ scope.row.money }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="in20" label="余额">
              <template slot-scope="scope">
                <div>{{ scope.row.balance }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="in20" label="卡片状态">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 0" style="color: #666">未激活</div>
                <div v-else-if="scope.row.status == 1" style="color: green">已激活</div>
                <div v-else-if="scope.row.status == 2" style="color: red">已冻结</div>
                <div v-else>已过期</div>
              </template>
            </el-table-column>
.
            <el-table-column prop="out90" label="使用状态">
              <template slot-scope="scope">
                <div v-if="scope.row.use_status == 0">未绑定</div>
                <div v-else-if="scope.row.use_status == 1">已绑定</div>
                <div v-else-if="scope.row.use_status == 2">已使用</div>
              </template>
            </el-table-column>

            <el-table-column prop="out90" label="用户ID">
              <template slot-scope="scope">
                <div>{{ scope.row.uid }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="out90" label="制卡人">
              <template slot-scope="scope">
                <div>{{ scope.row.admin_id }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="out90" label="操作人">
              <template slot-scope="scope">
                <div>{{ scope.row.operator_id }}</div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="操作" fixed="right" width="260">
              <template slot-scope="scope">
                

                <el-button class="qxbtn" type="danger" @click="editBtn(scope.row,'激活')">激活</el-button>
                <el-button class="qxbtn" type="danger" @click="editBtn(scope.row,'延期')">延期</el-button>
                <el-button class="qxbtn" type="danger" @click="editBtn(scope.row,'充值')">充值</el-button>
                <el-button class="qxbtn" type="danger" >明细</el-button>
                <el-button class="qxbtn" type="danger" >冻结</el-button>
                <el-button class="delbtn" type="danger" @click="delBtn(scope.row)">删除</el-button>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <!--table end-->

        <!-- 添加  编辑 -->
        <!-- dialog -->
        <el-dialog class="dialogHeaderno" width="625px" :visible.sync="dialogVisible" :before-close="handleClose">
          <div class="qxDialogHeader flex-column jc_c">
            <div class="flex jc_b ai_c">
              <span class="f16 colfff">{{ title }}</span>
              <i class="iconfont iconguanbi colfff f18" @click="dialogVisible = false"></i>
            </div>
          </div>
          <div class="ccmid">
            <el-form ref="form" :model="form">
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">类别：</span>
                <el-form-item>
                  <el-radio-group v-model="form.type" :disabled="title == '添加' ? false : true" class="xzradio">
                    <el-radio :label="1">满减卡</el-radio>
                    <el-radio :label="2">兑换卡</el-radio>
                    <el-radio :label="3">贵宾卡</el-radio>
                    <el-radio :label="4">代金券</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>

              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">批次：</span>
                <el-form-item label="" prop="money">
                  <el-input placeholder="批次" disabled v-model="$route.query.batch"></el-input>
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">面额：</span>
                <el-form-item label="" prop="money">
                  <el-input placeholder="面额" disabled v-model="form.money"></el-input>
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">有效期：</span>
                <el-form-item label="" prop="money">
                  <el-input placeholder="有效期" disabled v-model="form.expiration_day"></el-input>
                </el-form-item>
              </div>

              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">卡号：</span>
                <el-form-item label="" prop="money">
                  <el-input placeholder="输入卡号" disabled v-model="form.card_number"></el-input>
                </el-form-item>
              </div>
              <!-- <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">激活数量：</span>
                <el-form-item prop="batch">
                  <el-input placeholder="激活数量" v-model="form.num" disabled auto-complete="off"></el-input>
                </el-form-item>
              </div> -->

              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">限定城市：</span>
                <el-form-item label="" prop="condition">
                  <el-cascader :options="city_list" v-model="form.city_cus" disabled :props="{ multiple: true, checkStrictly: true, label: 'cname', value: 'cid' }" clearable></el-cascader>
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">指定影城：</span>
                <el-form-item prop="film">
                  {{ form.movie_name }}
                </el-form-item>
              </div>

              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">指定影片：</span>
                <el-form-item prop="film">
                  {{ form.film_name }}
                </el-form-item>
              </div>
              <div class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">制卡备注：</span>
                <el-form-item prop="batch">
                  <el-input type="textarea" disabled placeholder="输入备注" v-model="form.remarks"></el-input>
                </el-form-item>
              </div>

              <div v-if="title == '激活' || title == '冻结'" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">{{title=='冻结'?'是否激活/冻结：':'是否激活'}}</span>
                <el-form-item prop="batch">
                  <el-radio-group v-model="form.status" class="xzradio">
                    
                    <el-radio :label="1">已激活</el-radio>
                    <el-radio :label="0">未激活</el-radio>
                    <el-radio v-if="title=='冻结'" :label="2">冻结</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div v-if="title == '冻结'" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">冻结备注：</span>
                <el-form-item prop="batch">
                  <el-input type="textarea" placeholder="输入备注" v-model="form.frozen_reason"></el-input>
                </el-form-item>
              </div>
              <div v-if="title == '激活' || title == '冻结'" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">激活备注：</span>
                <el-form-item prop="batch">
                  <el-input type="textarea" placeholder="输入备注" v-model="form.activation_remarks"></el-input>
                </el-form-item>
              </div>
              
              <div v-if="title == '激活' || title == '冻结'" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">使用说明：</span>
                <el-form-item prop="batch">
                  <el-input type="textarea" placeholder="输入使用说明" v-model="form.des"></el-input>
                </el-form-item>
              </div>

              <div v-if="title == '延期'" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">更改有效期：</span>
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" v-model="form.expiration_day" type="datetime" placeholder="选择日期时间"> </el-date-picker>
              </div>

              <div v-if="title == '充值'" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">充值金额：</span>
                <el-form-item prop="batch">
                  <el-input placeholder="输入充值金额" v-model="form.recharge_money" auto-complete="off"></el-input>
                </el-form-item>
              </div>
              <div v-if="title == '充值'" class="qxmidm mt10 mb26">
                <span class="qxmidmtxt">充值备注：</span>
                <el-form-item prop="batch">
                  <el-input type="textarea" placeholder="输入充值备注" v-model="form.remarks"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="cancle">取 消</el-button>
            <el-button type="primary" @click="sure">确 定</el-button>
          </span>
        </el-dialog>

        <!-- 详情 -->
        <el-dialog class="dialogHeaderno" width="800px" :visible.sync="dialogInfo" :before-close="handleClose">
          <div class="qxDialogHeader flex-column jc_c">
            <div class="flex jc_b ai_c">
              <span class="f16 colfff">详情</span>
              <i class="iconfont iconguanbi colfff f18" @click="dialogInfo = false"></i>
            </div>
          </div>
          <div class="info_box" style="padding: 20px 80px">
            <div class="info_item">
              <div>名称：{{ details.title }}</div>
              <div>批次：{{ details.batch }}</div>
            </div>
            <div class="info_item">
              <div v-if="details.card_type == 1">类别：满减卡</div>
              <div v-if="details.card_type == 2">类别：兑换卡</div>
              <div v-if="details.card_type == 3">类别：贵宾卡</div>
              <div>金额：{{ details.money }}</div>
            </div>
            <div class="info_item">
              <div>手动领取：{{ details.is_manual == 1 ? '是' : '否' }}</div>
              <div>使用条件：{{ details.condition }}</div>
            </div>
            <div class="info_item">
              <div>有效期：{{ details.validity_time }}</div>
              <div>制卡数量：{{ details.card_quantity }}</div>
            </div>
            <div>弹窗：{{ details.cpm == 1 ? '是' : '否' }}</div>
            <div>领取人：{{ details.receiver_name }}</div>
            <div>限定影城：{{ details.movie_name }}</div>
            <div>指定影片：{{ details.film_name }}</div>
          </div>
        </el-dialog>

        <!-- 明细 -->
        <el-dialog class="dialogHeaderno" width="1000px" :visible.sync="dialogVipInfo" :before-close="handleClose">
          <div class="qxDialogHeader flex-column jc_c">
            <div class="flex jc_b ai_c">
              <span class="f16 colfff">明细</span>
              <i class="iconfont iconguanbi colfff f18" @click="dialogVipInfo = false"></i>
            </div>
          </div>
          <el-table :data="vipInfoList" style="width: 100%">
            <el-table-column prop="card_number" label="卡号"> </el-table-column>
            <el-table-column prop="change_type" label="类型">
              <template slot-scope="scope">
                 <div v-if="scope.row.change_type== 'vip_add' " style="display:flex;align-items: center;">
                   充值
                 </div>
                 <div v-if="scope.row.change_type== 'buy' " style="display:flex;align-items: center;">
                   购票
                 </div>
                 <div v-if="scope.row.change_type== 'refund' " style="display:flex;align-items: center;">
                   退款
                 </div>
                </template>
            </el-table-column>
            <el-table-column width="300" prop="orderno" label="订单号"> 
               <template slot-scope="scope">
                 <div style="display:flex;align-items: center;">
                   {{scope.row.orderno}}
                    <el-button
                        class="djbtn mr10"
                        style="
                            background: #2db7f5 !important;
                            margin-right: 10px;
                        "
                        v-clipboard:copy="scope.row.orderno"
                        v-clipboard:success="onCopySuccess"
                        v-clipboard:error="onCopyError"
                        >复制</el-button
                    >
                 </div>
                </template>
            </el-table-column>
            <el-table-column prop="money" label="金额"> </el-table-column>
            <el-table-column prop="balance" label="余额"> </el-table-column>
          </el-table>
        </el-dialog>

        <!-- <div class="page pt16">
          <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="total"> </el-pagination>
        </div> -->
        <BasePagination @page_change="page_change" :total_records="total" :max="maxPage" :now.sync="currentPage" :size.sync="pageSize"></BasePagination>
      </div>
    </div>

    <!-- <div v-if="showVeri">
      <el-dialog title="安全验证" :visible.sync="dialogVisibleVeri" width="30%" :before-close="handleClose">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="手机号码" prop="name">
            <span>15179027575</span>
          </el-form-item>
          <el-form-item label="验证码" prop="code1">
            <div class="item">
              <el-input v-model="ruleForm.code1"></el-input>
              <el-button @click="toSend('15179027575', 1)" :loading="isLoading1" :disabled="isLoading1" size="medium" type="primary">{{ title1 }}</el-button>
            </div>
          </el-form-item>
          <el-form-item label="手机号码" prop="name">
            <span>15107546894</span>
          </el-form-item>
          <el-form-item label="验证码" prop="code2">
            <div class="item">
              <el-input v-model="ruleForm.code2"></el-input>
              <el-button @click="toSend('15107546894', 2)" :loading="isLoading2" :disabled="isLoading2" type="primary">{{ title2 }}</el-button>
            </div>
          </el-form-item>
          <el-form-item label="手机号码" prop="name">
            <span>13429669222</span>
          </el-form-item>
          <el-form-item label="验证码" prop="code3">
            <div class="item">
              <el-input v-model="ruleForm.code3"></el-input>
              <el-button @click="toSend('13429669222', 3)" :loading="isLoading3" :disabled="isLoading3" type="primary">{{ title3 }}</el-button>
            </div>
          </el-form-item>
          <el-form-item v-if="phoneTips" label="验证提示">
            <div class="item" style="color:red;">
              {{phoneTips}}
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="resetForm">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div> -->

    <diaVeri @veriOk="veriOk" @handleClose="handleClose" :showVeri="showVeri"></diaVeri>
  </div>
</template>

<script>
import diaVeri from '@/components/verification'
export default {
  components: {
    diaVeri
  },
  watch: {
    currentPage() {
      this.getData()
    },
    pageSize() {
      this.getData()
    },
  },
  data() {
    return {
      loading: false,
      city_list: [],
      dialogVisible: false,
      dialogInfo: false,
      dialogVipInfo: false,

      select1: [
        {
          value: '-1',
          label: '全部（激活状态）',
        },
        {
          value: '0',
          label: '未激活',
        },
        {
          value: '1',
          label: '已激活',
        },
        {
          value: '2',
          label: '已冻结',
        },
        {
          value: '3',
          label: '已过期',
        },
      ],
      select2: [
        {
          value: '-1',
          label: '全部（使用状态）',
        },
        {
          value: '0',
          label: '未绑定',
        },
        {
          value: '1',
          label: '已绑定',
        },
        {
          value: '2',
          label: '已使用',
        },
        {
          value: '3',
          label: '已过期',
        },
      ],
      select3: [
        {
          value: '-1',
          label: '默认倒序',
        },
        {
          value: 'end_timedesc',
          label: '有效期',
        },
        {
          value: 'moneydesc',
          label: '面值',
        },
        {
          value: 'balancedesc',
          label: '金额',
        },
        {
          value: 'iddesc',
          label: '用户id',
        },
      ],
      select4: [
        {
          value: '-1',
          label: '默认正序',
        },
        {
          value: 'end_time',
          label: '有效期',
        },
        {
          value: 'money',
          label: '面值',
        },
        {
          value: 'balance',
          label: '金额',
        },
        {
          value: 'id',
          label: '用户id',
        },
      ],
      status: '',
      use_status: '',
      order_by_desc: '',
      order_by_esc: '',
      order_by: '',
      order_by_dis: false,
      order_by_desc_dis: false,
      card_number_start:'',
      card_number_end:'',
      id: '',

      vipInfoList: [],
      form: {
        title: '',
        batch: '',
        card_type: 2,
        money: '',
        receiver: '',
        is_manual: 1,
        condition: '',
        movie: '',
        card_quantity: 0,
        film: '',
        cpm: 1,
        expiration_day: 0,
        validity_time: '',
        card_user_quantity: 0,

        card_number_start: '',
        tp: 0,
        is_balance_pay: 0,
        city: [],
        remarks: '',
        des: '',
      },
      slect1: [],
      slect2: [],
      slect3: [],
      title: '添加',
      details: {},

      valSelect: '',
      select: {
        dataType: '',
        cinameType: '',
        searchType: '',
        valSerch: '',
      },
      selectOption: {
        dataOption: [
          {
            value: '2',
            label: '全部',
          },
          {
            value: '1',
            label: '旧数据',
          },
          {
            value: '0',
            label: '新数据',
          },
        ],
        cinameOption: [
          {
            value: '2',
            label: '全部',
          },
          {
            value: '1',
            label: '连锁',
          },
          {
            value: '0',
            label: '非连锁',
          },
        ],
        searchOption: [
          {
            value: '1',
            label: '影院',
          },
          {
            value: '2',
            label: '城市',
          },
          {
            value: '3',
            label: '地址',
          },
        ],
      },
      // 表格数据
      tableData: [],
      // 搜索条件
      condition: {
        limit: 10,
        offset: 0,
        tp: '',
        is_old: '',
        nm: '',
        addr: '',
        cname: '',
      },
      item: '',
      pageSize: 10,
      currentPage: 1,
      total: 0,
      maxPage: 1,

      plczDialog: false,
      bjxgDialog: false,
      batch: {
        nm: '',
        tp: '',
        in20: '0.95',
        from20to30: '0.925',
        from30to40: '0.92',
        from40to50: '0.915',
        from50to60: '0.91',
        from60to70: '0.895',
        from70to80: '0.89',
        from80to90: ' 0.885',
        out90: '0.88',
      },

      loading: false,
      ids: '',
      uid: '',

      // 短信验证
      showVeri: false,
      // dialogVisibleVeri: true,
      // isLoading1: false,
      // isLoading2: false,
      // isLoading3: false,
      // time1: 60,
      // timer1: null,
      // time2: 60,
      // timer2: null,
      // time3: 60,
      // timer3: null,
      // title1: '发 送',
      // title2: '发 送',
      // title3: '发 送',

      // ruleForm: {
      //   code1: '',
      //   code2: '',
      //   code3: '',
      // },
      // rules: {
      //   code1: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      //   code2: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      //   code3: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      // },
      // phoneTips:''
    }
  }, //data
  created() {
    this.getData()
    this.getCity()
    // this.getSelect1()
    // this.getSelect2()
    // this.getSelect3()
  },
  methods: {
    onCopySuccess(e) {
            this.$message.success("复制成功");
        },
        onCopyError(e) {
            this.$message.error("复制失败");
        },
    // 搜索
    toSearch(){
      this.getData()
    },
    // 短信验证-----------start
    // toSend(phone, type) {
    //   let taht = this
    //   this['timer' + type] = setInterval(function () {
    //     if (taht['time' + type] == 0) {
    //       clearInterval(taht['timer' + type])
    //       taht['isLoading' + type] = false
    //       taht['title' + type] = '发  送'
    //     } else {
    //       if (!taht['isLoading' + type]) {
    //         taht['isLoading' + type] = true
    //       }
    //       taht['time' + type]--
    //       taht['title' + type] = taht['time' + type] + 'S'
    //     }
    //   }, 1000)

    //   this.$api
    //     .sendSms({
    //       phone,
    //     })
    //     .then((res) => {
    //       if (res.data.code == 0) {
    //         this.$message({
    //           type: 'success',
    //           message: '发送成功!',
    //         })
    //       }
    //     })
    // },
    // submitForm(formName) {
    //   if(this.phoneTips){
    //     this.phoneTips = ''
    //   }
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       this.$api
    //         .phoneVerification({
    //           phone_verification:[
    //             {
    //               phone:'15179027575',
    //               code:this.ruleForm.code1,
    //             },
    //             {
    //               phone:'15107546894',
    //               code:this.ruleForm.code2,
    //             },
    //             {
    //               phone:'13429669222',
    //               code:this.ruleForm.code3,
    //             }
    //           ]
    //         })
    //         .then((res) => {
    //           console.log('res',res)
    //           if (res.data.code == 0) {
    //             this.$message({
    //               type: 'success',
    //               message: '验证成功!',
    //             })
    //             // 验证成功的缓存
    //             this.$localStorage.setItem('veri',{'veri':'ok'},1800)

    //             this.veriOk()
    //             this.showVeri = false
    //           }else{
    //             let phone_list = []
    //             res.data.data.map((item)=>{
    //               if(item.pmsg == 0){
    //                 phone_list.push(item.phone)
    //               }
    //             })
    //             this.phoneTips = phone_list.join(',') + '未通过验证，请核对'
    //           }
    //         })
    //     } else {
    //       console.log('error submit!!')
    //       return false
    //     }
    //   })
    // },
    // resetForm() {
    //   this.showVeri = false
    // },
    // 短信验证-----------end
    page_change(max) {
      console.log('max', max)
      this.currentPage = max ? max : 1
      this.getData()
    },

    select_change() {
      if (this.order_by_desc && !this.order_by_esc) {
        this.order_by_dis = true
      }
      if (!this.order_by_desc && this.order_by_esc) {
        this.order_by_desc_dis = true
      }
      this.order_by = this.order_by_esc ? this.order_by_esc : this.order_by_desc
      this.getData()
    },

    handleBack() {
      this.$router.push({
        path: this.$route.query.path,
      })
    },
    handleSelectionChange(e) {
      let list = []
      e.map((item) => {
        list.push(item.id)
      })
      this.ids = list.join(',')
      console.log(e)
      if (e.length == 1) {
        this.uid = e[0].uid
      }

      this.form = e[e.length - 1]
    },

    handleChange(value) {
      console.log(value)
    },
    handleClose() {
      this.dialogInfo = false
      this.dialogVisible = false
      this.dialogVipInfo = false
      this.showVeri = false
    },
    // 弹窗取消
    cancle() {
      this.dialogVisible = false
    },
    // 弹窗确定
    sure() {
      let data = {},
        that = this
      if (this.title == '激活') {
        data = {
          // id: this.form.id,
          // uid:this.$localStorage.getItem("userInfo")._id,
          status: this.form.status,
          des: this.form.des,
          activation_remarks: this.form.activation_remarks,
        }
      } else if (this.title == '延期') {
        data = {
          // id: this.form.id,
          expiration_day: this.form.expiration_day,
        }
      } else if (this.title == '充值') {
        data = {
          // id: this.form.id,
          recharge_money: this.form.recharge_money,
          remarks: this.form.remarks,
        }
      } else if (this.title == '冻结') {
        data = {
          // id: this.form.id,
          // uid:this.$localStorage.getItem("userInfo")._id,
          status: this.form.status,
          des: this.form.des,
          activation_remarks: this.form.activation_remarks,
        }
      }
      if (this.ids) {
        data = {
          ...data,
          id: this.ids,
        }
      }
      this.$api.setCardVip(data).then((res) => {
        if (res.data.code == 0) {
          this.dialogVisible = false
          this.$message({
            type: 'success',
            message: '编辑成功!',
          })
          setTimeout(function () {
            that.getData()
          }, 100)
        }
      })
    },

    filter(val) {
      let offset = (val - 1) * this.pageSize
      let data = {
        limit: this.pageSize,
        offset: offset,
        card_id: this.$route.query.id,
      }
      return data
    }, //filter
    // 获取数据
    getData() {
      const that = this
      that.loading = true
      let data = {
        page_size: this.pageSize,
        page: this.currentPage,
        card_id: this.$route.query.id,
        status: this.status,
        use_status: this.use_status,
        order_by: this.order_by,
        card_number_start:this.card_number_start,
        card_number_end:this.card_number_end,
        uid: this.id
      }
      this.$api
        .getCardVip(data)
        .then((res) => {
          that.loading = false
          if (res.data.code == 0) {
            that.total = res.data.data.count
            that.maxPage = res.data.data.pages
            that.tableData = res.data.data.rows
          }
        })
        .catch((err) => {
          that.loading = false
        })
    },
    // 获取影片
    async getSelect1(nm) {
      let res = await this.$api.sysGetData({
        nm,
      })
      if (res.data.code == 0) {
        this.slect1 = res.data.data
        // this.slect1.unshift({
        //   nm: '全部',
        //   mid: '',
        // })
      }
    },
    // 获取影城
    async getSelect2(nm) {
      let res = await this.$api.sysGetCinema({
        nm,
      })
      if (res.data.code == 0) {
        this.slect2 = res.data.data
        // this.slect2.unshift({
        //   nm: '全部',
        //   cinemaid: '',
        // })
      }
    },
    // 获取用户
    async getSelect3(id) {
      let res = await this.$api.sysGetUser({
        id,
      })

      if (res.data.code == 0) {
        this.slect3 = res.data.data
        // this.slect3.unshift({
        //   nickname: '全部',
        //   id: '',
        // })
      }
    },
    toVeri(title, methodType) {
      if (!this.ids) {
        this.$message({
          type: 'warning',
          message: '请勾选数据后操作',
        })
      } else {
        let veri= this.$localStorage.getItem('veri')? this.$localStorage.getItem('veri').veri:''
        if(veri){
          this.title = title
          this.methodType = methodType
          this.veriOk()
        }else{
          this.title = title
          this.methodType = methodType
          this.showVeri = true
        }
        
      }
    },
    veriOk() {
      // 5冻结  6删除
      if (this.methodType == 1 || this.methodType == 2 || this.methodType == 3 || this.methodType == 5) {
        this.editBtn(this.title)
      } else if (this.methodType == 4) {
        this.vipInfo()
      } else {
        this.delBtn(this.title)
      }
    },
    // 详情
    infoBtn({ id }) {
      this.dialogInfo = true
      this.$api.getCardDetail({ card_id: id }).then((res) => {
        this.details = res.data.data
      })
    },
    // 编辑
    editBtn(type) {
      this.dialogVisible = true
      this.title = type
      // this.form = obj
    },
    delBtn() {
      this.$confirm('正在进行删除操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // console.log(obj)
          this.$api.setCardVip({ id: this.ids, is_deleted: 1 }).then((res) => {
            if (res.data.code == 0) {
              this.dialogVisible = false
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.getData()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    vipInfo() {
      console.log(this.ids)
      if (this.ids == '' || this.ids.indexOf(',') != -1) {
        this.$message({
          type: 'warning',
          message: '请选择单条数据',
        })
      } else {
        // card_vip_id	10	string	是
        // 卡劵记录id
        // page	1	string	是
        // 默认1
        // page_size	10	string	是
        // 默认10
        // uid		string	是
        // 用户id
        let data = {
          card_vip_id: this.ids,
          page: 1,
          page_size: 10000,
          uid: this.uid,
        }
        this.$api.getCardVipLog(data).then((res) => {
          if (res.data.code == 0) {
            this.dialogVipInfo = true
            this.vipInfoList = res.data.data.rows
          }
        })
      }
    },
    getCity() {
      this.$api.getCityLinkage().then((res) => {
        console.log('res', res)
        if (res.data.code == 0) {
          res.data.data.map((item, index) => {
            item.children.map((ite, idx) => {
              ite.cid = ite.rid
              ite.cname = ite.name
            })
          })
          this.city_list = res.data.data
        }
      })
    },

    // 搜索按钮
    searchBtn() {},

    // 编辑按钮
    editConfirm() {},
    // 批量操作按钮
    batchConfirm() {},
  }, //methods
}
</script>

<style lang="less" scoped>
.el-button {
  margin: 10px;
}
.info_box {
  div {
    height: 60px;
    text-align: left;
  }
  .info_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div {
      flex: 1;
    }
  }
}
// .el-form-item {
//   margin-bottom: 10px;
// }
// /deep/ .el-table_1_column_15 .cell {
//   overflow: visible;
// }
// /deep/ .el-input--suffix .el-input__inner {
// 	color: #409EFF !important;
// }
// /deep/ .el-input--suffix .el-input__inner{
// 	border-color: #ccc !important;
// }
.box{

}
.tableBox{
  height: 580px;
  overflow-y: scroll;
}
.titles {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  .el-select {
    margin-right: 12px;
  }
  > :first-child{
    .number-des{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      .el-input{
        width: 217px;
        &:not(:first-child){
          margin-left: 10px;
        }
      }
    }
  }
}

.expandForm .el-form-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-input {
    padding-right: 40px;
  }
}
</style>